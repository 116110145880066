export interface LoginFormData {
  email: string;
  password: string;
}

export interface LoginPayload {
  recaptchaToken: string;
  email: string;
  password: string;
  recaptchaFallback: boolean;
}

// Login Error Data
export enum LoginErrorCode {
  MFA_REQUIRED = 'mfa_required',
  FAILED = 'failed',
  INVALID_CREDENTIALS = 'invalid_credentials',
  LOCKED_ACCOUNT = 'locked_account',
  INVALID_REQUEST = 'invalid_request',
  INVALID_V3_TOKEN = 'invalid_recaptcha_v3_token',
  INVALID_V2_TOKEN = 'invalid_recaptcha_v2_token',
}
